<template>
  <div class="users-form">
    <a-spin :spinning="loading" :tip="labels.form.loading">
      <validation-observer ref="observer">
        <a-form
          :model="form"
          :label-width="80"
          slot-scope="{ validate }"
          @submit.prevent="validate().then(handleSubmit)"
        >
          <!-- ACTION BUTTONS -->
          <a-row
            type="flex"
            justify="space-between"
            :gutter="16"
            :style="{ marginBottom: '15px' }"
          >
            <!-- Title -->
            <a-col :xs="24" :md="12">
              <h2 class="form-title">
                <a-icon
                  :type="isEdit ? 'edit' : 'user-add'"
                  class="form-icon"
                />
                <span>{{
                  isEdit ? labels.form.titleEdit : labels.form.titleAdd
                }}</span>
              </h2>
            </a-col>
            <!-- Actions -->
            <a-col :xs="24" :md="12">
              <a-row
                type="flex"
                justify="end"
                :gutter="16"
                :style="{
                  alignItems: 'flex-end',
                  marginBottom: '0px',
                }"
              >
                <a-col>
                  <a-button-group>
                    <a-button
                      type="danger"
                      icon="arrow-left"
                      ghost
                      @click="handleCancel"
                    >
                      <span>{{ labels.form.goBack }}</span>
                    </a-button>
                    <template v-if="canEdit || allowCreditCardModification">
                      <a-button
                        type="primary"
                        ghost
                        :loading="submitting"
                        @click="validate().then(() => handleSubmit(true))"
                      >
                        <span>{{ labels.form.saveAndClose }}</span>
                      </a-button>
                      <a-button
                        type="primary"
                        :loading="submitting"
                        @click="validate().then(handleSubmit)"
                      >
                        <span>{{ labels.form.save }}</span>
                      </a-button>
                    </template>
                  </a-button-group>
                </a-col>
              </a-row>
            </a-col>
          </a-row>

          <!-- TABS -->
          <a-row type="flex">
            <a-col :span="24">
              <a-tabs v-model="activeKey" type="card" class="user-tabs">
                <a-tab-pane key="general">
                  <template #tab>
                    <span>
                      <a-icon type="user" class="form-icon" />
                      {{ labels.form.tabs.generalInfo.title }}
                    </span>
                  </template>

                  <!-- USER GENERAL INFO -->
                  <a-row
                    v-if="activeKey === 'general'"
                    type="flex"
                    justify="center"
                    :gutter="[30, 30]"
                  >
                    <a-col :xs="24" :sm="24" :md="8">
                      <a-card v-if="user">
                        <a-row>
                          <a-col
                            :span="24"
                            :style="{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }"
                          >
                            <a-avatar
                              v-if="form.avatar"
                              shape="square"
                              :size="206"
                              :src="`${form.avatar}`"
                            />
                            <a-avatar
                              v-else
                              shape="square"
                              :size="206"
                              icon="user"
                            />
                          </a-col>
                          <a-col :span="24">
                            <div class="user">
                              <h1 class="user-name">{{ fullname }}</h1>
                              <p class="user-joined">
                                {{ labels.form.joinedAt }}
                                <span>{{
                                  user.created_at
                                    | moment("DD MMM, YYYY HH:ss A")
                                }}</span>
                              </p>
                              <a-divider style="margin: 5px 0" />
                              <span class="referral-content">
                                <span class="referral-content-label"
                                  ><strong>{{
                                    labels.form.referralCode
                                  }}</strong></span
                                >
                                <span
                                  v-if="!edittingCode"
                                  class="referral-content-value"
                                  ><a-button
                                    type="link"
                                    :href="referralLink"
                                    target="_blank"
                                    >{{ form.referral_code }}</a-button
                                  >
                                  <a-icon
                                    @click="copy()"
                                    type="copy"
                                    theme="filled"
                                    class="copy-icon"
                                    :title="labels.copyLinkToClipboard"
                                  />
                                  <a-button
                                    class="edit-icon"
                                    type="link"
                                    icon="edit"
                                    @click="edittingCode = true"
                                  />
                                </span>
                                <span v-else class="referral-content-value">
                                  <a-input
                                    v-model="form.referral_code"
                                    :placeholder="labels.form.referralCode"
                                    :readOnly="!canEdit"
                                    style="width: 50%; display: inline-block"
                                    @change="
                                      onlyAlfaNumeric(
                                        $event.target.value,
                                        'referral_code',
                                        true
                                      )
                                    "
                                  >
                                    <a-icon
                                      slot="addonAfter"
                                      type="save"
                                      @click="validateCode"
                                      style="cursor: pointer; color: #1890ff"
                                    />
                                  </a-input>
                                </span>
                                <a-divider style="margin: 5px 0" />
                              </span>
                              <div
                                class="referredby-content"
                                v-if="user?.referred_by"
                              >
                                <span class="referredby-content-label">
                                  <strong>{{ labels.form.referedBy }}</strong>
                                </span>
                                <span class="referredby-content-value">
                                  {{
                                    `${user.referred_by?.firstname || ""} ${
                                      user.referred_by?.lastname || ""
                                    }`?.trim()
                                  }}
                                  ({{ user.referred_by.referral_code }})</span
                                >
                              </div>
                            </div>
                          </a-col>
                        </a-row>
                      </a-card>
                    </a-col>
                    <a-col :xs="24" :sm="24" :md="16">
                      <a-card>
                        <a-row type="flex" justify="space-between">
                          <a-col :span="24">
                            <h2 class="card-title">
                              {{ labels.form.title }} {{ fullname }}
                            </h2>
                            <a-divider style="margin: 5px 0" />
                          </a-col>
                        </a-row>
                        <a-row
                          v-if="canChangeCommissionPercentage"
                          type="flex"
                          justify="space-between"
                          :gutter="24"
                        >
                          <a-col :xs="24" :sm="24" :md="12">
                            <switch-input
                              v-model="form.isSeller"
                              :label="labels.form.is_seller.label"
                            />
                          </a-col>
                          <a-col v-if="form.isSeller" :sm="24" :md="12">
                            <select-validation
                              v-model="form.commission_percentage"
                              :placeholder="
                                labels.form.commission_percentage.placeholder
                              "
                              :label="labels.form.commission_percentage.label"
                              :dataSource="percentages"
                              :allowClear="true"
                            />
                          </a-col>
                        </a-row>
                        <a-row type="flex" justify="space-between" :gutter="24">
                          <a-col :xs="24" :sm="24" :md="12">
                            <Input
                              v-model="form.firstname"
                              :label="labels.form.firstname.label"
                              rules="required"
                              :placeholder="labels.form.firstname.placeholder"
                              @input="onlyAlfa($event, 'firstname')"
                              :readOnly="!canEdit"
                            />
                          </a-col>
                          <a-col :xs="24" :sm="24" :md="12">
                            <Input
                              v-model="form.lastname"
                              :label="labels.form.lastname.label"
                              rules="required"
                              :placeholder="labels.form.lastname.placeholder"
                              @input="onlyAlfa($event, 'lastname')"
                              :readOnly="!canEdit"
                            />
                          </a-col>
                        </a-row>
                        <a-row type="flex" justify="space-between" :gutter="24">
                          <a-col :xs="24" :sm="24" :md="12">
                            <Input
                              v-model="form.identification_card"
                              v-mask="'###-#######-#'"
                              :maxLength="13"
                              :label="labels.form.identification_card.label"
                              :placeholder="
                                labels.form.identification_card.placeholder
                              "
                              :readOnly="!canEdit"
                            />
                          </a-col>
                          <a-col :xs="24" :sm="24" :md="12">
                            <Input
                              v-model="form.whatsapp"
                              :label="labels.form.whatsapp.label"
                              v-mask="'(###) ###-####'"
                              rules="required"
                              :maxLength="14"
                              :placeholder="labels.form.whatsapp.placeholder"
                              :readOnly="!canEdit"
                            />
                          </a-col>
                        </a-row>
                        <a-row type="flex" justify="start" :gutter="24">
                          <a-col :xs="24" :sm="24" :md="12">
                            <select-component
                              v-model="form.transaction_profile"
                              :label="labels.form.transaction_profile.label"
                              :placeholder="
                                labels.form.transaction_profile.placeholder
                              "
                              :fetchService="handleFetchTransactionProfiles"
                              sortOptionsBy="name"
                              labelKey="name"
                              valueKey="id"
                              :allowClear="true"
                              :disabled="!canEdit"
                            />
                          </a-col>
                          <a-col :xs="24" :sm="24" :md="12">
                            <select-component
                              v-model="form.commission_profile"
                              :label="labels.form.commission_profile.label"
                              :placeholder="
                                labels.form.commission_profile.placeholder
                              "
                              :fetchService="handleFetchCommissionProfiles"
                              sortOptionsBy="name"
                              labelKey="name"
                              valueKey="id"
                              :allowClear="true"
                              :disabled="!canEdit"
                            />
                          </a-col>
                        </a-row>
                        <a-row type="flex" justify="space-between" :gutter="24">
                          <a-col :xs="24" :sm="24" :md="12">
                            <Input
                              v-model="form.email"
                              :label="labels.form.email.label"
                              rules="required|email"
                              :placeholder="labels.form.email.placeholder"
                              :readOnly="!canEdit"
                            />
                          </a-col>
                          <a-col :xs="24" :sm="24" :md="12">
                            <Input
                              v-model="form.password"
                              type="password"
                              :label="labels.form.password.label"
                              :rules="`${isEdit ? '' : 'required|'}min:6`"
                              :placeholder="labels.form.password.placeholder"
                              :readOnly="!canEdit"
                            />
                          </a-col>
                        </a-row>
                        <a-row type="flex" justify="space-between" :gutter="24">
                          <a-col :xs="24" :sm="24" :md="12">
                            <Input
                              v-model="form.city"
                              :label="labels.form.city.label"
                              :placeholder="labels.form.city.placeholder"
                              @input="onlyAlfa($event, 'city')"
                              :readOnly="!canEdit"
                            />
                          </a-col>
                          <a-col :xs="24" :sm="24" :md="12">
                            <select-component
                              v-model="form.province"
                              :label="labels.form.province.label"
                              :placeholder="labels.form.province.placeholder"
                              :fetchService="fetchProvinces"
                              sortOptionsBy="name"
                              labelKey="name"
                              valueKey="id"
                              :allowClear="true"
                              :disabled="!canEdit"
                            />
                          </a-col>
                        </a-row>
                        <a-row type="flex" justify="space-between" :gutter="24">
                          <a-col :xs="24" :sm="24" :md="24">
                            <text-area
                              v-model="form.address"
                              :label="labels.form.address.label"
                              :placeholder="labels.form.address.placeholder"
                              :customStyle="{ resize: 'none' }"
                              :readOnly="!canEdit"
                            />
                          </a-col>
                        </a-row>
                        <a-row type="flex" justify="space-between" :gutter="24">
                          <a-col :xs="24" :sm="24" :md="6">
                            <switch-input
                              v-model="form.is_credit_card_enabled"
                              :label="labels.form.is_credit_card_enabled.label"
                              :disabled="
                                !canEdit && !allowCreditCardModification
                              "
                            />
                          </a-col>
                          <a-col :xs="24" :sm="24" :md="6">
                            <switch-input
                              v-model="form.status"
                              :label="labels.form.status"
                              :disabled="!canEdit"
                            />
                          </a-col>
                          <a-col :xs="24" :sm="24" :md="6">
                            <switch-input
                              v-model="form.email_verified"
                              :label="labels.form.email_verified"
                              :disabled="!canEdit"
                            />
                          </a-col>
                          <a-col :sm="24" :md="6">
                            <switch-input
                              v-model="form.whatsapp_verified"
                              :label="labels.form.whatsapp_verified"
                              :disabled="!canEdit"
                            />
                          </a-col>
                        </a-row>
                      </a-card>
                    </a-col>
                  </a-row>
                </a-tab-pane>

                <a-tab-pane key="assignedLotteries">
                  <template #tab>
                    <span>
                      <a-icon type="unordered-list" class="form-icon" />
                      {{ labels.form.tabs.assignedLotteries.title }}
                    </span>
                  </template>
                  <assigned-lotteries
                    v-if="activeKey === 'assignedLotteries'"
                    :dataSource="form.assigned_lotteries"
                    :loading="loading"
                    :labels="labels.form.assignedLotteries"
                    @change="form.assigned_lotteries = $event"
                    :onlyUnassignedTo="form.id"
                  />
                </a-tab-pane>

                <a-tab-pane
                  v-if="allowCreditCardModification"
                  key="creditCards"
                >
                  <template #tab>
                    <span>
                      <a-icon type="credit-card" class="form-icon" />
                      {{ labels.form.tabs.creditCards.title }}
                    </span>
                  </template>
                  <credit-cards
                    v-if="
                      activeKey === 'creditCards' && allowCreditCardModification
                    "
                    :dataSource="form.credit_cards"
                    :loading="loading"
                    :labels="labels.form.creditCards"
                    @change="form.credit_cards = $event"
                  />
                </a-tab-pane>
              </a-tabs>
            </a-col>
          </a-row>
        </a-form>
      </validation-observer>
    </a-spin>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import labels from "@/utils/labels";
import Input from "../../../components/core/VeeValidateForm/Input.vue";
import TextArea from "../../../components/core/VeeValidateForm/TextArea.vue";
import SwitchInput from "../../../components/core/VeeValidateForm/Switch.vue";
import SelectComponent from "../../../components/core/SelectPagination";
import SelectValidation from "../../../components/core/VeeValidateForm/Select";
import { mapGetters, mapActions, mapState } from "vuex";
import _ from "lodash";
import {
  adminPageTitle,
  CRAUE_CONFIG,
  hasRoles,
  roles,
  toBase64,
} from "../../../utils/utils";
import router from "@/router";
import AssignedLotteries from "./AssignedLotteries.vue";
import CreditCards from "./CreditCards.vue";

const initialFormState = {
  referral_code: "",
  firstname: "",
  lastname: "",
  identification_card: "",
  whatsapp: "",
  whatsapp_verified: false,
  email: "",
  email_verified: false,
  province: null,
  city: "",
  address: "",
  password: "",
  avatar: "",
  status: true,
  roles: [],
  not_robot: false,
  acept_terms: false,
  isSeller: false,
  is_credit_card_enabled: false,
  commission_percentage: [],
  commission_profile: [],
  transaction_profile: [],
  assigned_lotteries: [],
  credit_cards: [],
};

export default {
  name: "FormUser",
  components: {
    ValidationObserver,
    Input,
    TextArea,
    SwitchInput,
    SelectComponent,
    SelectValidation,
    AssignedLotteries,
    CreditCards,
  },
  metaInfo: {
    title: adminPageTitle(),
    titleTemplate: (titleChunk) => {
      return titleChunk
        ? `${titleChunk} - ${
            router.currentRoute.name.includes("edit")
              ? labels.users.pageTitleEditing
              : labels.users.pageTitleCreate
          }`
        : `${titleChunk}`;
    },
  },
  data() {
    return {
      labels: labels.users,
      exception: labels.exception,
      loading: false,
      submitting: false,
      percentages: [],
      form: _.cloneDeep(initialFormState),
      activeKey: "general",
      edittingCode: false,
    };
  },
  async created() {
    this.resetForm();
    this.loadPercentages();
    this.fetchAdminCountries();
    if (this.$route.params.id) {
      this.fetchData();
    } else {
      this.$message.error(this.exception.ERROR_NOT_FOUND);
      this.$router.push({ name: "admin.users" });
    }
  },
  computed: {
    ...mapState("users", ["user"]),
    ...mapGetters({
      roles: "users/getRoles",
    }),
    ...mapGetters("adminAuth", ["getLoggedUser"]),
    allowCreditCardModification() {
      const { accessRoles } = this.getLoggedUser;
      return hasRoles(accessRoles, [roles.admin.ROLE_USER_CREDIT_CARD_MODIFY]);
    },
    serverDateFormat() {
      return "YYYY-MM-DD HH:mm:ss";
    },
    canChangeCommissionPercentage() {
      const { accessRoles } = this.getLoggedUser;
      return hasRoles(accessRoles, [
        roles.admin.ROLE_USERS_COMMISSIONS_PERCENTAGE_ASSIGN,
      ]);
    },
    isEdit() {
      return !!this.$route.params?.id;
    },
    canEdit() {
      const { accessRoles } = this.getLoggedUser;
      return (
        !this.isEdit || hasRoles(accessRoles, [roles.admin.ROLE_USERS_UPDATE])
      );
    },
    fullname() {
      return this.user ? `${this.user.firstname} ${this.user.lastname}` : "";
    },
    referralLink() {
      return this.user
        ? `${location.origin}${
            this.$router.resolve({
              name: "register",
              query: {
                referralId: this.form.referral_code,
              },
            }).href
          }`
        : "";
    },
  },
  methods: {
    ...mapActions(["fetchProvinces"]),
    ...mapActions("systemConfig", ["getCraueConfigByName"]),
    ...mapActions("users", [
      "fetchUser",
      "createUser",
      "updateUser",
      "deleteUser",
      "resetForm",
    ]),
    ...mapActions("commissionProfiles", ["fetchCommissionProfiles"]),
    ...mapActions("transactionProfiles", ["fetchTransactionProfiles"]),
    ...mapActions("systemConfig", ["fetchAdminCountries"]),
    validateCode() {
      if (this.form.referral_code) {
        this.edittingCode = false;
      } else {
        this.$message.error(this.labels.form.referralCodeRequired);
      }
    },
    handleFetchCommissionProfiles(options) {
      Object.assign(options, {
        onlyActives: true,
      });
      return this.fetchCommissionProfiles(options);
    },
    handleFetchTransactionProfiles(options) {
      Object.assign(options, {
        onlyActives: true,
      });
      return this.fetchTransactionProfiles(options);
    },
    async fetchData() {
      try {
        this.loading = true;
        await this.fetchUser(this.$route.params.id);
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message
        );
        this.$router.push({ name: "admin.users" });
      } finally {
        this.loading = false;
      }
    },
    async handleSubmit(closeAfterSave = false) {
      if (this.submitting) return;
      this.submitting = true;
      let response = null;
      let editting = this.isEdit;
      try {
        const data = _.cloneDeep(this.form);
        if (data?.assigned_lotteries?.length > 0) {
          data.assigned_lotteries = data.assigned_lotteries.map((item) => {
            Object.assign(item, {
              start_date: item.start_date
                ? this.$moment(item.start_date).format(this.serverDateFormat)
                : null,
            });
            return item;
          });
        }

        if (data?.credit_cards?.length > 0) {
          await data.credit_cards.reduce(async (promise, item) => {
            await promise;
            if (!_.isString(item?.image) && item?.image) {
              item.image = await toBase64(item.image);
            }
            return item;
          }, Promise.resolve());
        }

        if (editting) {
          response = await this.updateUser(data);
        } else {
          response = await this.createUser(data);
        }
        if (response) this.$message.success(response.message);
        if (closeAfterSave) {
          this.$router.push({ name: "admin.users" });
        } else {
          if (!editting) {
            this.resetForm();
            this.$router.push({
              name: "admin.users.edit",
              params: { id: response.data.id },
            });
          }
        }
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message
        );
        this.$log.error(error);
      } finally {
        this.submitting = false;
      }
    },
    handleCancel() {
      this.$router.push({ name: "admin.users" });
    },
    async onlyAlfa(value = "", field, upperCase = false) {
      this.form[field] = await value?.replace(/[^a-zA-Z\s]/g, "");
      if (upperCase) {
        this.form[field] = this.form[field].toUpperCase();
      }
    },
    async onlyAlfaNumeric(value = "", field, upperCase = false) {
      this.form[field] = await value?.replace(/[^a-zA-Z0-9\s]/g, "");
      if (upperCase) {
        this.form[field] = this.form[field].toUpperCase();
      }
    },
    async loadPercentages() {
      try {
        const { data } = await this.getCraueConfigByName(
          CRAUE_CONFIG.VAR_USER_COMMISSIONS_OPTIONS
        );
        if (data && data.value) {
          this.percentages = JSON.parse(data.value).map((item) => {
            return {
              label: `${item}%`,
              value: item,
            };
          });
        } else {
          this.percentages = [];
        }
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message
        );
        this.$log.error(error);
      }
    },
    copy() {
      navigator.clipboard.writeText(this.referralLink);
      document.execCommand("copy");
      this.$message.success(this.labels.copied);
    },
  },
  watch: {
    "$route.params.id"(value, oldValue) {
      if (value && value !== oldValue) {
        this.fetchData();
      }
    },
    user: {
      handler(user = {}) {
        Object.assign(
          this.form,
          user
            ? _.cloneDeep({
                ...user,
                status: !!user?.status,
                whatsapp_verified: !!user?.whatsapp_verified,
                email_verified: !!user?.email_verified,
              })
            : _.cloneDeep(initialFormState)
        );
      },
      deep: true,
    },
  },
  beforeDestroy() {
    this.form = _.cloneDeep(initialFormState);
    this.resetForm();
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/variable";

.users-form {
  .form-title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  .form-icon {
    margin-right: 8px;
  }

  .form-button-group {
    margin-top: 16px;
  }

  .user {
    font-family: "Montserrat", sans-serif;
    text-align: center;
    padding: 15px 0;

    &-name {
      font-size: 22px;
      font-weight: bold;
    }

    &-joined {
      font-size: 14px;
      color: color(--gray-1);

      span {
        font-weight: bold;
      }
    }
  }

  .referredby-content,
  .referral-content {
    &-label {
      font-size: 16px;
      margin-right: 10px;
    }
    &-value {
      font-size: 16px;
      margin-top: 8px;
      a {
        margin: 0;
        padding: 0;
        color: color(primary);
        border-radius: 0;
        &:hover {
          border-bottom: 1px solid color(primary);
        }
      }
      .copy-icon {
        margin-left: 8px;
        cursor: pointer;
        color: color(primary);
      }
    }
  }

  .user-tabs {
    .smtp-config-icon {
      margin-right: 10px;
      width: 21px;
    }
    .policies-terms-icon,
    .global-variable-icon {
      margin-right: 10px;
    }
  }
}
.ant-input[disabled],
.ant-time-picker-input[disabled],
.ant-input-number-disabled,
.ant-select-disabled .ant-select-selection {
  background-color: color(--white) !important;
  color: rgba(0, 0, 0, 0.65) !important;
}
</style>
